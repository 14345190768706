.allProducts{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 80px;
}
.head{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.head h1{
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.head hr{
    width: 100px;
    height: 2px;
    background-color: grey;
}
@media(max-width:500px){
    .allProducts{
        display: block;
    }
}
.navbar{
    display: flex;
    width:100%;
    height:100px;
    background-color: #7eb6d3;
    border-bottom: 0.1px solid black;
    justify-content: center;
    /* position: fixed; */
}
.nav-logo{
    display: flex;
    width:30%;
    height:100%;
    align-self: flex-start;
    justify-content: center;
    align-items: center;
    gap:10px;
}
.nav-logo img{
    height: 70px;
    width: 70px;
    border-radius: 20px;
}
.nav-logo p{
    color: #043715;
    font-size: 25px;
    font-weight: 1000;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;   
}
.nav-dropdown{
    display: none;
}
.nav-menu{
    list-style: none;
    display: flex;
    align-items: center;
    color: #626262;
    gap:50px;
    font-size: 20px;
    font-weight: 500;
}
.nav-menu li{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3px;
    cursor: pointer;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    
}
.nav-login-cart{
    display: flex;
    align-items: center;
    margin-left: 300px;
}
.nav-login-cart img{
    height: 50px;
    width: 50px;
}
.nav-cart-count{
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -35px;
    margin-left: -55px;
    border-radius: 11px;
    font-size: 14px;
    background: red;
    color: white;
}
.nav-menu hr{
    border: none;
    width: 80%;
    height: 3px;
    border-radius: 10px;
    background: #FF4141;
}

@media(max-width:500px){
    .nav-logo{
        flex-direction: column;
        justify-content: center;
        align-items:normal;
        gap:10px;
    }
    .nav-logo img{
        height: 55px;
        width: 55px;
        border-radius: 10px;
        align-self: center;
    }
    .nav-logo p{
        font-size: 20px;
        font-weight: 1300;
        width: 250px;
        align-self: center;
    }
    .nav-menu{
        display: none;
        height: 70px;
        width:85%;
        position: absolute;
        background-color: whitesmoke;
        justify-content: center;
        top:100px;
        border-radius: 10px;
    }
    .nav-menu li{
        font-size: 12px;
        cursor: pointer;
    }
    .nav-dropdown{
        display: block;
        width:40px;
        transition: 0.5s;
        height:40px;
        align-self: center;
        margin-left: 70px;
        rotate: 90deg;
    }
    .nav-login-cart{
        margin-left: 140px;
    }
    .nav-menu-visible{
        display: flex;
        margin-left: 20px;
    }
    .nav-login-cart img{
        height: 35px;
        width: 35px;
    }
    .nav-cart-count{
        margin-left: -12px;
    }

}
@media(max-width:430px){
    .nav-logo img{
        height: 40px;
        width: 40px;
    }
    .nav-logo p{
        font-size: 20px;
    }
    .nav-menu{
        height: 60px;
        width:80%;
        position: absolute;
        justify-content: center;
        align-items: center;
        top:100px;
        border-radius: 10px;
    }
    .nav-menu li{
        font-size: 10px;
    }
    .nav-cart-count{
        margin-left: -10px;
    }
    .nav-login-cart{
        margin-left: 100px;
    }
}
@media(max-width:370px){
    .nav-logo img{
        height: 37px;
        width: 37px;
        
    }
    .nav-logo p{
        font-size: 20px;
    }
    .nav-menu{
        height: 60px;
        width:80%;
        position: absolute;
        justify-content: center;
        align-items: center;
        top:100px;
        border-radius: 10px;
    }
    .nav-menu li{
        font-size: 8px;
    }
    .nav-cart-count{
        margin-left: -10px;
    }
    .nav-menu-visible{
        margin-left: 10px;
    }
    .nav-login-cart{
        margin-left: 80px;
    }
}
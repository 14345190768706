.front{
    display: flex;
    flex:1;
    background-color: white;
    margin-bottom: 80px;
    /* background: linear-gradient(180deg,#ebf0ec,#ebf0ed22 60%); */
}
.front-left{
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: -50px;
    margin-bottom: 50px;
}
.front-left p{
    font-size: 60px;
    font-weight: 800;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.hand-icon img{
    height: 80px;
    width:80px;
}
.front-right{
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;

}
.front-right img{
    height: 400px;
    width: 400px;
    border-radius: 30px;
}

@media(max-width:912px){
    .front-right img{
        height: 370px;
        width: 370px;
    }
    .front-left p{
        font-size: 45px;
    }
}

@media(max-width:500px){
    .front{
        display: block;
    }
    .front-right img{
        height: 450px;
        width: 450px;
    }
    .front-left p{
        font-size: 40px;
    }
    .hand-icon img{
        height: 40px;
        width:40px;
    }
}

@media(max-width:430px){
    .front-right img{
        height: 370px;
        width: 370px;
    }
    .front-left p{
        font-size: 35px;
    }
}
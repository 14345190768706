.combo{
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.head{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.head h1{
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.head hr{
    width: 100px;
    height: 2px;
    background-color: grey;
}

@media(max-width:500px){
    .combo{
        display: block;
    }
}
.products-item{
    margin-top: 50px;
    display: flex;
    gap:30px;
}
@media(max-width:500px){
    .combo{
        display:block;
    }
    .products-item{
        margin-left: 20px;
        margin-right: 20px;
        overflow-x: auto;
        scrollbar-width: none;
    }
} 
.item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 350px;
    height: 450px;
    box-shadow: 0 20px 20px -15px black;
    border-radius: 20px;
    gap: 30px;
}
.item img{
    height: 320px;
    width: 320px;
    border-radius: 20px;
}
.container{
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: aquamarine;
    width: 350px;
    height: 123px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: #7eb6d3;
    border-top: 1px solid rgb(3, 26, 71);
}
.container p{
    font-size: 18px;
    font-weight: 700;
    margin-left: 20px;
    margin-top: 8px;
}
.item-prices{
    display: flex;
    gap: 30px;
    margin-top: 10px;
}
.item-price-new{
    color: #374151;
    font-size: 18px;
    font-weight: 600;
    margin-left: 20px;
}
.item-price-old{
    color: #8c8c8c;
    font-size: 18px;
    font-weight: 500;
    text-decoration: line-through;
}
.container button{
    background-color: green;
    color: white;
    width: 100px;
    height:40px;
    border: none;
    outline: none;
    border-radius: 15px;
    margin-left: 40px;
    font-weight: 600;
    padding: 10px;
    box-shadow: 0 10px 10px -8px black;
}

@media(max-width:500px){
    .container{
        width: 300px;
        height: 100px;
        margin-top: 30px;
    }
    .item{
        height: 400px;
    }
    .main-item{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 300px;
        height: 450px;
        border-radius: 20px ;
    }
    .item img{
        height: 270px;
        width: 270px;
    }
    .container button{
        height:30px;
        border-radius: 10px;
        margin-left: 5px;
        font-size: 15px;
        padding: 2px;
    }
    .container p{
        font-size: 16px;
    }
}

.star-rating {
    display: inline-block;
  }
  
  .star {
    color: #ccc;
    font-size: 20px;
    padding: 0 2px;
  }
  
  .star.filled {
    color: gold;
  }
.address-form{
    display: flex;
    flex-direction: column;
    background-color: whitesmoke;
    padding: 20px;
    width: 70%;
    margin: auto;
    border-radius: 10px;
    margin-bottom: 100px;
    box-shadow: 0 20px 15px rgba(0, 0, 0, 0.1);}
.form-group{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.form-group label{
    font-size: 16px;
    font-weight: 700;
}
.form-group textarea{
    width: 80%;
    height: 100px;
    border-radius: 10px;
    border: 0.1px solid black;
    margin: 10px;
}
.form-group input{
    width: 80%;
    height: 40px;
    border-radius: 10px;
    border: 0.1px solid black;
    margin: 10px;
}
.address-form button{
    width: 200px;
    margin-top: 30px;
    margin-left: 420px;
    height: 50px;
        background-color: green;
        color: white;
        font-weight: 700;
        font-size: 16px;
        outline: none;
        border: none;
        border-radius: 10px;
}

@media(max-width:500px){
    .address-form button{
        width: 150px;
        margin-top: 30px;
        margin-left: 90px;
        
    }
}

@media(max-width:470px){
    .address-form button{
        width: 150px;
        margin-top: 30px;
        margin-left: 80px;
        
    }
}

@media(max-width:430px){
    .address-form button{
        width: 150px;
        margin-top: 30px;
        margin-left: 74px;
        
    }
}

@media(max-width:380px){
    .address-form button{
        width: 150px;
        margin-top: 30px;
        margin-left: 60px;
        
    }
}
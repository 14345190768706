/* Loader.css */
.loader {
    border: 5px solid #f3f3f3; /* Light grey */
    border-top: 5px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 2s linear infinite;
    margin: 10px auto; /* Center the loader */
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  .loader_cont p{
    text-align: center;
    font-size: 20px;
    font-family: Georgia, 'Times New Roman', Times, serif;
  }
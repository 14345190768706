.image-margin{
    margin-top: 100px;
    margin-bottom: 100px;

}
.Images-container1{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin-bottom: 100px;
}
.Images-container2{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
}
.Images-container1 img{
    height: 500px;
    width: 500px;
    border-radius: 50px;
}
.Images-container2 img{
    height: 500px;
    width: 500px;
    border-radius: 50px;
}


@media(max-width:500px){
    .Images-container1{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 30px;
        margin-bottom: 30px;
    }
    .Images-container2{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 30px;
    }
    .Images-container1 img{
        height: 340px;
        width: 340px;
        border-radius: 30px;
    }
    .Images-container2 img{
        height: 340px;
        width: 340px;
        border-radius: 30px;
    }
}

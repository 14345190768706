.review {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  }
  .heading{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .heading h1{
    font-size: 30px;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  }
  .cards{
    overflow-x: scroll;
    padding: 20px;
    margin-bottom: 150px;
    margin-left:70px;
    margin-right: 70px;
  }
  .review hr{
      height: 2px;
      width: 250px;
      background: grey;
      margin-bottom: 20px;
  }
  .reviews-container {
    display: flex;
    gap: 20px;
  }
  .review-card {
    width: 500px;
    height: 400px; 
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  .customer-image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-bottom: 10%;
  }
  
  .customer-details {
    padding: 10px 0;
  }
  
  .customer-name {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  }
  .customer-review {
    font-size: 16px;
    color: #555;
    margin-top: 15px;
    font-family: 'Courier New', Courier, monospace;
  }

  @media(max-width:500px){
    .heading h1{
      font-size: 18px;
      /* font-style: italic; */
    }
    .heading hr{
      width:100px;
      height: 1px;
      background: grey;
    }
    .cards{
      overflow-x: scroll;
      padding: 20px;
      margin-bottom: 20px;
      margin-left:0px;
      margin-right: 10px;
    }
    .review-card{
      background-color: whitesmoke;
    }
  }